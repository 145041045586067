
export default {
  name: "Calendar",
  props: {
    chosenDate: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    deliveryDays: {
      required: false,
      default: () => [],
      type: Array,
    },
    width: {
      required: false,
      default: "100%",
      type: String,
    },
    yearly: {
      required: false,
      default: false,
      type: Boolean,
    },
    fullScreen: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      calendarDate: this.$moment(),
      days: ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat", "Sun"],
      dates: [],
      blanks: "",
      today: {
        moment: this.$moment(),
        number: this.$moment().format("DD"),
        month: this.$moment().format("MMMM"),
        year: this.$moment().format("YYYY"),
      },
      monthDiff: 0,
    }
  },
  mounted() {
    this.getDates()
  },
  methods: {
    getDates() {
      this.blanks = this.calendarDate.startOf("month").isoWeekday() - 1
      this.monthDiff = this.calendarDate.diff(
        this.today.moment.startOf("month"),
        "months",
        true
      )

      this.dates = Array(this.calendarDate.daysInMonth())
        .fill(null)
        .map((_, i) => {
          const date = this.calendarDate.startOf("month").clone().add(i, "day")
          return {
            number: date.format("DD"),
            day: date.format("dddd"),
            month: date.format("MMMM"),
            year: date.format("YYYY"),
            full: date.format("YYYY-MM-DD"),
            string: date.format("dddd Do MMMM YYYY"),
            available: true,
          }
        })
      if (this.deliveryDays.length > 0) {
        this.dates = this.dates.map((date) => {
          const matchingObject = this.deliveryDays.find(
            (obj) => obj.full === date.full
          )
          return matchingObject
            ? { ...date, ...matchingObject }
            : { ...date, available: false }
        })
      }
    },
    prevYear() {
      this.calendarDate = this.calendarDate.subtract(1, "year")
      this.getDates()
    },
    nextYear() {
      this.calendarDate = this.calendarDate.add(1, "year")
      this.getDates()
    },
    prevMonth() {
      this.calendarDate = this.calendarDate.subtract(1, "month")
      this.getDates()
    },
    nextMonth() {
      this.calendarDate = this.calendarDate.add(1, "month")
      this.getDates()
    },
    chooseDate(date) {
      this.$emit("returnDate", date)
      this.$emit("closeCalendar")
    },
  },
}
