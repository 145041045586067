import createDeliveryDays from "../utils/createDeliveryDays"
import debounce from "../utils/debounce"

export const state = () => ({
  accountLoading: true,
  authenticated: false,
  authLoading: false,
  customerLoading: false,
  customerRole: false,
  billingAddress: false,
  bundles: false,
  cart: false,
  cartError: false,
  checkoutError: false,
  cartLoading: false,
  chosenBundle: false,
  chosenCuisine: false,
  chosenDeal: false,
  chosenProduct: false,
  chosenMaker: false,
  chosenMakerCategory: false,
  chosenOrder: false,
  cuisines: false,
  customer: false,
  dealId: false,
  reviews: false,
  deliveryAddress: false,
  deliveryFilter: false,
  deliveryLoading: false,
  deliveryNotes: false,
  discountError: false,
  discount: false,
  discountLoading: false,
  filteredMakers: false,
  filteredProductCategories: false,
  homeLoading: true,
  isBiz: process.env.SUBDOMAIN === "business",
  isProduction: process.env.IS_PRODUCTION,
  locatorIsOpen: false,
  loginError: false,
  makers: false,
  makerCategories: false,
  makerLoading: false,
  newVerificationError: false,
  newVerificationSuccess: false,
  orderNotes: false,
  order: false,
  orders: false,
  ordersLoading: false,
  organisationLoading: false,
  organisation: false,
  products: false,
  productCategories: false,
  quiverRadius: false,
  resetConfirmation: false,
  resetPasswordError: false,
  resetPasswordSuccess: false,
  rewards: false,
  rewardsLoading: false,
  rewardSelected: false,
  sameDayChosen: false,
  searchResults: false,
  showCalendar: false,
  showCart: false,
  showOptions: false,
  showSearch: false,
  showServices: false,
  signUpSuccess: false,
  signUpError: false,
  socialSharing: [
    {
      network: "email",
      icon: "envelope",
    },
    {
      network: "whatsapp",
      icon: "whatsapp",
    },
    {
      network: "facebook",
      icon: "facebook",
    },
    {
      network: "twitter",
      icon: "x-twitter",
    },
    {
      network: "linkedin",
      icon: "linkedin",
    },
  ],
  tags: false,
  templateLoading: true,
  user: false,
  verifySuccess: false,
  verifyError: false,
  verificationLoading: false,
  windowSize: 0,
})

export const mutations = {
  SET_HOME_LOADING(state, payload) {
    state.homeLoading = payload
  },
  SET_TEMPLATE_LOADING(state, payload) {
    state.templateLoading = payload
  },
  SET_MAKER_LOADING(state, payload) {
    state.makerLoading = payload
  },
  SET_AUTH_LOADING(state, payload) {
    state.authLoading = payload
  },
  SET_PRODUCT_CATEGORIES(state, payload) {
    state.productCategories = payload.filter(
      (category) =>
        category.is_active &&
        category.biz === state.isBiz &&
        category.products.length > 0 &&
        category.products.some((product) => product.is_active)
    )
  },
  SET_FILTERED_PRODUCT_CATEGORIES(state, payload) {
    if (payload) {
      state.filteredProductCategories = state.productCategories.map(
        (category) => {
          return {
            ...category,
            products: category.products.filter((product) => {
              return payload.every((allergen) =>
                allergen.list
                  ? product.allergens.every(
                      (item) => !allergen.list.includes(item.name)
                    ) &&
                    allergen.list.every(
                      (item) =>
                        !product.name.toLowerCase().includes(item) &&
                        !product.description.toLowerCase().includes(item)
                    )
                  : product[allergen.toggle]
              )
            }),
          }
        }
      )
    } else {
      state.filteredProductCategories = payload
    }
  },
  SET_SEARCH_STATUS(state, payload) {
    state.showSearch = payload
  },
  SET_CALENDAR_STATUS(state, payload) {
    state.showCalendar = payload
  },
  SET_OPTIONS_STATUS(state, payload) {
    state.showOptions = payload
  },
  SET_SHOW_SERVICES(state, payload) {
    state.showServices = payload
  },
  SET_CART_STATUS(state, payload) {
    state.showCart = payload
    state.showOptions = false
    state.showSearch = false
    state.showCalendar = false
    window.scrollTo(0, 0)
  },
  SET_BUNDLES(state, payload) {
    state.bundles = payload
  },
  SET_CHOSEN_BUNDLE(state, payload) {
    state.chosenBundle = payload
  },
  SET_CHOSEN_PRODUCT(state, payload) {
    state.chosenProduct = payload
  },
  SET_CUISINES(state, payload) {
    state.cuisines = payload
  },
  SET_TAGS(state, payload) {
    state.tags = payload
  },
  SET_SEARCH_RESULTS(state, payload) {
    state.searchResults = payload
    if (payload) {
      state.showCalendar = false
      state.showOptions = false
    }
  },
  SET_CHOSEN_CUISINE(state, payload) {
    state.chosenCuisine = payload
  },
  SET_ORDER(state, payload) {
    state.order = payload
    if (payload.id) {
      localStorage.setItem(payload.id, JSON.stringify(payload))
    }
  },
  SET_ORDER_NOTES(state, payload) {
    state.orderNotes = payload
    state.cart = { ...state.cart, orderNotes: payload }
  },
  SET_DELIVERY_NOTES(state, payload) {
    state.deliveryNotes = payload
    state.cart = { ...state.cart, deliveryNotes: payload }
  },
  SET_DELIVERY_DATE(state, payload) {
    state.sameDayChosen = payload.sameDay
    const newCart = {
      ...state.cart,
      items: state.cart.items.filter((item) =>
        payload.sameDay
          ? item.product.same_day === true
          : item.product.future_day === true
      ),
      date: payload,
    }
    state.cart = newCart
    state.cartError = payload?.selectedWindow
      ? false
      : "Please select a date and time"
    localStorage.setItem("and-dine-cart", JSON.stringify(newCart))
  },
  SET_DELIVERY_FILTER(state, payload) {
    state.deliveryFilter = payload
    if (payload) {
      state.filteredMakers = state.makers.filter((maker) =>
        maker.delivery_days.find(
          (day) => day.full === payload.full && day.available
        )
      )
      if (state.cart) {
        state.cart = { ...state.cart, date: payload }
      }
    }
  },
  SET_SELECTED_WINDOW(state, payload) {
    const newCart = {
      ...state.cart,
      date: { ...state.cart.date, selectedWindow: payload },
    }
    state.cart = newCart
    state.cartError = false
    localStorage.setItem("and-dine-cart", JSON.stringify(newCart))
  },
  SET_DELIVERY_WINDOWS(state, payload) {
    const deliveryWindows = []
    const endTime = this.$moment()
      .endOf("day")
      .hour(payload?.closeTime < 17 ? payload?.closeTime : 17)
    const startTime = payload.sameDay
      ? this.$moment()
          .startOf("day")
          .hour(this.$moment().add(1, "hours").format("HH"))
      : this.$moment()
          .startOf("day")
          .hour(
            payload?.openTime < (state.quiverRadius ? 9 : 7)
              ? payload?.openTime
              : state.quiverRadius
              ? 9
              : 7
          )
    while (startTime.isBefore(endTime)) {
      const windowStart = startTime.format("HH")
      startTime.add(1, "hours")
      deliveryWindows.push({
        name: windowStart,
        value: windowStart,
      })
    }
    state.cart.date.deliveryWindows = deliveryWindows
  },
  SET_LOGIN_ERROR(state, payload) {
    // TODO: Make this more robust, we should be presenting error messages based on unique error codes/ids that we send from the BE
    if (payload === "Customer account not activated") {
      state.loginError =
        "Your account needs to be verified, please check your email for instructions to verify or go to https://anddine.co.uk/verify/new to request a new verification email."
    } else {
      state.loginError = payload
    }
  },
  SET_SIGN_UP_SUCCESS(state, payload) {
    state.signUpSuccess = payload
  },
  SET_SIGN_UP_ERROR(state, payload) {
    state.signUpError = payload
  },
  SET_VERIFY_ERROR(state, payload) {
    state.verifyError = payload
  },
  SET_VERIFY_SUCCESS(state, payload) {
    state.verifySuccess = payload
  },
  SET_AUTHENTICATED(state, payload) {
    state.authenticated = payload
    state.customerRole = payload?.role || false
    localStorage.setItem("authenticated", JSON.stringify(payload))
  },
  SET_DELIVERY_ADDRESS(state, payload) {
    state.deliveryAddress = payload
    payload && localStorage.setItem("address", JSON.stringify(payload))
    state.cart = { ...state.cart, deliveryAddress: payload }
  },
  SET_BILLING_ADDRESS(state, payload) {
    state.billingAddress = payload
    state.cart = { ...state.cart, billingAddress: payload }
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload
    payload && localStorage.setItem("customer", JSON.stringify(payload))
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_DISCOUNT_LOADING(state, payload) {
    state.discountLoading = payload
  },
  SET_DISCOUNT_ERROR(state, payload) {
    state.discountError = payload
  },
  SET_CHOSEN_DISCOUNT(state, payload) {
    state.discount = payload
  },
  SET_CHOSEN_DEAL(state, payload) {
    state.chosenDeal = payload
  },
  SET_REWARDS(state, payload) {
    state.rewards = payload
  },
  SET_REWARD_SELECTED(state, payload) {
    state.rewardSelected = payload
  },
  SET_ORDERS(state, payload) {
    state.orders = payload
  },
  SET_CHOSEN_ORDER(state, payload) {
    state.chosenOrder = payload
  },
  SET_REVIEWS(state, payload) {
    state.reviews = payload
  },
  SET_QUIVER_RADIUS(state, payload) {
    state.quiverRadius = payload
  },
  SET_MAKERS(state, payload) {
    state.makers = payload
      .filter(
        (maker) =>
          maker.is_active &&
          maker.makersCategories.some((cat) => cat.biz === state.isBiz)
      )
      .map((maker) => createDeliveryDays(maker, state.cart))
  },
  SET_CHOSEN_MAKER(state, payload) {
    state.chosenMaker = createDeliveryDays(payload, state.cart)
  },
  SET_MAKER_CATEGORIES(state, payload) {
    const randomCategories = payload
      .map((category) => ({
        ...category,
        makers: category.makers
          .filter((maker) => maker.is_active)
          .sort(() => 0.5 - Math.random()),
      }))
      .filter(
        (category) => category.biz === state.isBiz && category.makers.length > 0
      )
      .sort(() => 0.5 - Math.random())
    state.makerCategories = randomCategories
  },

  SET_CHOSEN_MAKER_CATEGORY(state, payload) {
    state.chosenMakerCategory = payload[0]
  },
  SET_FILTERED_MAKERS(state, payload) {
    state.filteredMakers = payload
      ? payload
          .map((maker) => createDeliveryDays(maker, state.cart))
          .filter((maker) =>
            maker.makersCategories.some((cat) => cat.biz === state.isBiz)
          )
      : payload
  },
  SET_CART_LOADING(state, payload) {
    state.cartLoading = payload
  },
  SET_ACCOUNT_LOADING(state, payload) {
    state.accountLoading = payload
  },
  SET_CART(state, payload) {
    if (payload) {
      state.cart = {
        ...payload,
        date: payload?.date || false,
      }
      state.cartError = payload.date?.selectedWindow
        ? false
        : "Please select a date and time"
      localStorage.setItem("and-dine-cart", JSON.stringify(state.cart))
    } else {
      state.cart = false
      localStorage.setItem("and-dine-cart", false)
    }
  },
  SET_RESET_CONFIRMATION(state, payload) {
    state.resetConfirmation = payload
  },
  SET_CHECKOUT_ERROR(state, payload) {
    state.checkoutError = payload
  },
  SET_CART_ERROR(state, payload) {
    state.cartError = payload
  },
  SET_NEW_VERIFICATION_SUCCESS(state, payload) {
    state.newVerificationSuccess = payload
  },
  SET_VERIFICATION_LOADING(state, payload) {
    state.verificationLoading = payload
  },
  SET_NEW_VERIFICATION_ERROR(state, payload) {
    state.newVerificationError = payload
  },
  SET_RESET_PASSWORD_ERROR(state, payload) {
    state.resetPasswordError = payload
  },
  SET_RESET_PASSWORD_SUCCESS(state, payload) {
    state.resetPasswordSuccess = payload
  },
  SET_CUSTOMER_LOADING(state, payload) {
    state.customerLoading = payload
  },
  SET_WINDOW_SIZE(state, payload) {
    state.windowSize = payload
  },
  SET_ORGANISATION_LOADING(state, payload) {
    state.organisationLoading = payload
  },
  SET_ORGANISATION(state, payload) {
    state.organisation = payload
  },
  SET_DEAL_ID(state, payload) {
    state.dealId = payload
  },
}

export const actions = {
  clearCart({ commit }) {
    commit("SET_CART", false)
    commit("SET_ORDER", false)
    commit("SET_CART_LOADING", false)
  },
  clearOrder({ commit }, id) {
    localStorage.removeItem(id)
  },
  async googleAuth({ commit, dispatch }, payload) {
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/auth/google`, payload.token)
      .then((response) => {
        commit("SET_AUTHENTICATED", response.data)
        dispatch("getCustomer", payload.redirect)
      })
      .catch((error) => {
        commit(
          "SET_LOGIN_ERROR",
          `${error.response.data} - Please try again or try logging in using another method`
        )
        commit("SET_AUTHENTICATED", false)
        this.$sentry.captureException(error)
      })
  },
  async getTags({ commit, dispatch }) {
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/tags?limit=9999&filter=[{"key":"is_linked","value":1},{"key":"is_active","value":1}]`
      )
      .then((response) => {
        commit("SET_TAGS", response.data.tags)
        dispatch("getCuisines")
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_HOME_LOADING", false)
      })
  },
  async getCuisines({ commit }) {
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/cuisines?limit=9999&filter=[{"key":"is_linked","value":1},{"key":"is_active","value":1}]`
      )
      .then((response) => {
        commit("SET_CUISINES", response.data.models)
        commit("SET_HOME_LOADING", false)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_HOME_LOADING", false)
      })
  },
  deepSearch: debounce(async function ({ commit, state }, search) {
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makers/deepSearch?searchName=${search}&is_biz=${state.isBiz}`
      )
      .then((response) => {
        commit("SET_SEARCH_RESULTS", response.data)
      })
      .catch((error) => {
        commit("SET_SEARCH_RESULTS", false)
        this.$sentry.captureException(error)
      })
  }, 400),
  async deepFilter({ commit }, payload) {
    const tags = payload?.tags
      ? `&tags=${JSON.stringify(payload.tags.map((tag) => tag.id))}`
      : false
    const cuisines = payload?.cuisines
      ? `&cuisines=${JSON.stringify(
          payload.cuisines.map((cuisine) => cuisine.id)
        )}`
      : false
    const query = {}
    if (payload.tags) {
      query.tags = JSON.stringify(payload.tags.map((tag) => tag.id))
    }
    if (payload.cuisines) {
      query.cuisines = JSON.stringify(
        payload.cuisines.map((cuisine) => cuisine.id)
      )
    }
    if (Object.keys(query).length > 0) {
      this.$router.push({ query })
    }
    if (tags || cuisines) {
      await this.$axios
        .get(
          `${
            process.env.AND_DINE_API
          }/api/public/makers/deepFilter?is_active=1${tags || ""}${
            cuisines || ""
          }`
        )
        .then((response) => {
          commit("SET_FILTERED_MAKERS", response.data)
        })
        .catch((error) => {
          this.$sentry.captureException(error)
        })
    } else {
      commit("SET_FILTERED_MAKERS", false)
    }
  },
  async getMakers({ commit, dispatch }) {
    commit("SET_HOME_LOADING", true)
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/makers?limit=999`)
      .then((response) => {
        commit("SET_MAKERS", response.data.models)
        dispatch("getBundles")
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_HOME_LOADING", false)
      })
  },
  async getMakerCategories({ commit }) {
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makerCategories?limit=9999&filter=[{"key":"is_active","value":1}]`
      )
      .then((response) => {
        commit("SET_MAKER_CATEGORIES", response.data.models)
        commit("SET_TEMPLATE_LOADING", false)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_TEMPLATE_LOADING", false)
      })
  },
  async getMakerCategory({ commit }, name) {
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makerCategories/search?name=${name}&is_active=1`
      )
      .then((response) => {
        commit("SET_CHOSEN_MAKER_CATEGORY", response.data.models)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getMakerBySlug({ commit }, payload) {
    commit("SET_MAKER_LOADING", true)
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makers/slugs/${payload.slug}`
      )
      .then((response) => {
        if (!response.data.is_active && payload.makerPage) {
          this.$router.push("/")
        }
        if (response.data.is_active) {
          commit("SET_CHOSEN_MAKER", response.data)
          commit("SET_PRODUCT_CATEGORIES", response.data.productCategories)
          commit("SET_MAKER_LOADING", false)
        }
      })
      .catch((error) => {
        commit("SET_MAKER_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  async searchMakers({ commit }, slug) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/makers/search?slug=${slug}`)
      .then((response) => {
        commit("SET_CHOSEN_MAKER", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getBundles({ commit, dispatch }) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/bundles`)
      .then((response) => {
        commit("SET_BUNDLES", response.data.bundles)
        dispatch("getTags")
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_HOME_LOADING", false)
      })
  },
  async getBundlesById({ commit }, id) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/bundles/${id}`)
      .then((response) => {
        commit("SET_CHOSEN_BUNDLE", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getProductsBySlug({ commit }, slug) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/products/slugs/${slug}`)
      .then((response) => {
        commit("SET_CHOSEN_PRODUCT", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getProductById({ commit }, id) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/products/${id}`)
      .then((response) => {
        commit("SET_CHOSEN_PRODUCT", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getProductBySlug({ commit }, slug) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/products/slugs/${slug}`)
      .then((response) => {
        commit("SET_CHOSEN_PRODUCT", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async getDealById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${process.env.HUBSPOT_TOKEN}`,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.HUBSPOT_API}/deals/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_DEAL", response.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
  },
  async createCustomer({ commit }, payload) {
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/public/customers/register`,
        payload
      )
      .then(() => {
        commit("SET_SIGN_UP_SUCCESS", true)
        commit("SET_SIGN_UP_ERROR", false)
      })
      .catch((error) => {
        commit("SET_SIGN_UP_SUCCESS", false)
        commit("SET_SIGN_UP_ERROR", error.response.data)
        commit("SET_AUTHENTICATED", false)
        this.$sentry.captureException(error)
      })
  },
  customerLogout({ commit }, redirect) {
    redirect && this.$router.push(redirect)
    commit("SET_USER", false)
    commit("SET_ORDERS", false)
    commit("SET_REWARDS", false)
    commit("SET_AUTHENTICATED", false)
  },
  async verifyCustomer({ commit }, payload) {
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/public/customers/verify/${payload.verificationId}`
      )
      .then(() => {
        commit("SET_VERIFY_SUCCESS", true)
        commit("SET_VERIFY_ERROR", false)
      })
      .catch((error) => {
        commit("SET_VERIFY_SUCCESS", false)
        commit("SET_VERIFY_ERROR", error.response.data)
        this.$sentry.captureException(error)
      })
  },
  async generateNewVerification({ commit }, payload) {
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/public/customers/verify/resend`,
        payload
      )
      .then(() => {
        commit("SET_NEW_VERIFICATION_SUCCESS", true)
        commit("SET_NEW_VERIFICATION_ERROR", false)
        commit("SET_VERIFICATION_LOADING", false)
      })
      .catch((error) => {
        commit("SET_NEW_VERIFICATION_SUCCESS", false)
        commit("SET_NEW_VERIFICATION_ERROR", error.response.data)
        commit("SET_VERIFICATION_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  async forgotPassword({ commit }, payload) {
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/public/customers/forgotPassword`,
        payload
      )
      .then(() => {
        commit("SET_RESET_CONFIRMATION", true)
      })
      .catch((error) => {
        commit("SET_RESET_CONFIRMATION", false)
        commit("SET_SIGN_UP_ERROR", error.response.data)
      })
  },
  async resetCustomerPassword({ commit }, payload) {
    commit("SET_CUSTOMER_LOADING", true)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/public/customers/resetPassword`,
        payload
      )
      .then(() => {
        commit("SET_CUSTOMER_LOADING", false)
        commit("SET_RESET_PASSWORD_SUCCESS", true)
        commit("SET_RESET_PASSWORD_ERROR", false)
      })
      .catch((error) => {
        commit("SET_CUSTOMER_LOADING", false)
        commit("SET_RESET_PASSWORD_SUCCESS", false)
        commit("SET_RESET_PASSWORD_ERROR", error.response.data)
      })
  },
  async customerLogin({ commit, dispatch }, payload) {
    commit("SET_AUTH_LOADING", true)
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/public/customers/login`,
        payload.credentials
      )
      .then((response) => {
        commit("SET_AUTHENTICATED", response.data)
        dispatch("getCustomer", payload.redirect)
      })
      .catch((error) => {
        commit("SET_LOGIN_ERROR", error.response.data)
        commit("SET_AUTHENTICATED", false)
        commit("SET_AUTH_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  async getCustomer({ commit, state, dispatch }, redirect) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/customers/profile`, config)
      .then((response) => {
        commit("SET_USER", response.data)
        commit("SET_LOGIN_ERROR", false)
        commit("SET_AUTH_LOADING", false)
        redirect && this.$router.push(redirect)
      })
      .catch((error) => {
        commit("SET_AUTHENTICATED", false)
        commit("SET_AUTH_LOADING", false)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async updateCustomer({ commit, state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .put(`${process.env.AND_DINE_API}/api/customers`, payload, config)
      .then(() => {
        commit("SET_SIGN_UP_ERROR", false)
        dispatch("getCustomer", "/account")
      })
      .catch((error) => {
        commit("SET_SIGN_UP_ERROR", error.response.data)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async getRewards({ commit, state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/customers/rewards`, config)
      .then((response) => {
        commit("SET_REWARDS", response.data)
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async addDiscount({ commit, state }, payload) {
    commit("SET_DISCOUNT_LOADING", true)
    commit("SET_DISCOUNT_ERROR", false)
    const body = { discount_code: payload.discount_code }
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/public/orders/applyDiscount/${payload.id}`,
        body
      )
      .then((response) => {
        commit("SET_DISCOUNT_LOADING", false)
        commit("SET_CHOSEN_DISCOUNT", {
          ...response.data.dataValues,
          discount_code: payload.discount_code,
        })
        commit("SET_ORDER", {
          ...response.data.dataValues,
          paymentIntent: response.data.paymentIntent,
          maker: state.order.maker,
          orderProducts: state.order.orderProducts,
        })
      })
      .catch((error) => {
        commit("SET_DISCOUNT_LOADING", false)
        commit("SET_DISCOUNT_ERROR", "Discount code not valid")
        this.$sentry.captureException(error)
      })
  },
  async addReward({ commit, state, dispatch }, payload) {
    commit("SET_DISCOUNT_LOADING", true)
    commit("SET_DISCOUNT_ERROR", false)
    const body = { reward_id: payload.reward_id }
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/customers/orders/applyReward/${payload.id}`,
        body,
        config
      )
      .then((response) => {
        commit("SET_DISCOUNT_LOADING", false)
        commit("SET_REWARD_SELECTED", {
          ...response.data.dataValues,
          discount_code: payload.discount_code,
        })
        commit("SET_ORDER", {
          ...response.data.dataValues,
          paymentIntent: response.data.paymentIntent,
          maker: state.order.maker,
          orderProducts: state.order.orderProducts,
        })
      })
      .catch((error) => {
        commit("SET_DISCOUNT_LOADING", false)
        commit("SET_DISCOUNT_ERROR", "Reward not valid")
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async addBundleToCart({ commit, state, dispatch }, payload) {
    let updatedItems = state.cart?.items || []
    const makerId = payload?.maker?.id || state.cart?.maker?.id
    payload.bundle.forEach((bundle) => {
      const index = updatedItems.findIndex(
        (item) =>
          item.product.id === bundle.product.id &&
          item.appliedModifiers.every(
            (modifier, index) =>
              modifier.modifier_id ===
              bundle.appliedModifiers[index].modifier_id
          )
      )
      if (index === -1) {
        updatedItems = [
          ...updatedItems,
          {
            product: bundle.product,
            quantity: bundle.quantity,
            appliedModifiers: bundle.appliedModifiers,
          },
        ]
      } else {
        updatedItems = [
          ...updatedItems.slice(0, index),
          {
            product: bundle.product,
            quantity: bundle.quantity,
            appliedModifiers: bundle.appliedModifiers,
          },
          ...updatedItems.slice(index + 1),
        ].filter((item) => item.quantity > 0)
      }
    })
    const body = {
      items: updatedItems.map((item) => {
        return {
          id: item.product.id,
          name: item.product.name,
          quantity: item.quantity,
          appliedModifiers: item.appliedModifiers,
        }
      }),
      maker_id: makerId,
    }
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/public/orders/cart`, body)
      .then((response) => {
        const newCart = {
          ...response.data,
          maker: {
            id: payload.maker.id,
            name: payload.maker.name,
            slug: payload.maker.slug,
            address: payload.maker.address,
            delivery_days: payload.maker.delivery_days,
            next_available: payload.maker.next_available,
            free_delivery_min_amount: payload.maker.free_delivery_min_amount,
            min_order_amount: state.isBiz
              ? payload.maker.min_order_amount || 1500
              : 1500,
          },
          date: payload.date || false,
          items: updatedItems.map((item) => {
            return {
              product: {
                id: item.product.id,
                name: item.product.name,
                attachments: item.product.attachments,
                same_day: item.product.same_day,
                future_day: item.product.future_day,
                min_order_amount: item.product.min_order_amount,
                price: item.product.price,
              },
              quantity: item.quantity,
              appliedModifiers: item.appliedModifiers,
            }
          }),
        }
        commit("SET_CART_LOADING", false)
        commit("SET_CART", newCart)
        newCart.date && commit("SET_DELIVERY_WINDOWS", newCart.date)
        state.deliveryAddress && dispatch("createOrder")
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        this.$sentry.captureException(error)
      })
    state.isProduction &&
      this.$gtm.push({
        event: "add_to_cart",
        ...{
          currency: "GBP",
          value: state.cart.total,
          items: updatedItems.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: state.chosenMaker.name,
              item_category:
                state.productCategories &&
                state.productCategories.length &&
                state.productCategories.find(
                  (category) => category.id === item.categoryId
                )?.name,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      })
  },
  addToCart: debounce(async function ({ commit, state, dispatch }, payload) {
    let updatedItems = state.cart?.items || payload.items || []
    const makerId = payload?.maker?.id || state.cart?.maker?.id
    if (payload.product) {
      const index = updatedItems.findIndex(
        (item) =>
          item.product.id === payload.product.id &&
          item.appliedModifiers.every(
            (modifier, index) =>
              modifier.modifier_id ===
              payload.appliedModifiers[index].modifier_id
          )
      )
      if (index === -1) {
        updatedItems = [
          ...updatedItems,
          {
            product: payload.product,
            quantity: payload.quantity,
            appliedModifiers: payload.appliedModifiers,
          },
        ]
      } else {
        updatedItems = [
          ...updatedItems.slice(0, index),
          {
            product: payload.product,
            quantity: payload.quantity,
            appliedModifiers: payload.appliedModifiers,
          },
          ...updatedItems.slice(index + 1),
        ].filter((item) => item.quantity > 0)
      }
    }
    const body = {
      items: updatedItems.map((item) => {
        return {
          id: item.product.id,
          name: item.product.name,
          quantity: item.quantity,
          appliedModifiers: item.appliedModifiers,
        }
      }),
      maker_id: makerId,
    }
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/public/orders/cart`, body)
      .then((response) => {
        const newCart = {
          ...response.data,
          dealId: payload.dealId || null,
          maker: {
            id: payload.maker.id,
            name: payload.maker.name,
            slug: payload.maker.slug,
            address: payload.maker.address,
            delivery_days: payload.maker.delivery_days,
            next_available: payload.maker.next_available,
            free_delivery_min_amount: payload.maker.free_delivery_min_amount,
            min_order_amount: state.isBiz
              ? payload.maker.min_order_amount || 1500
              : 1500,
          },
          date: payload.date || false,
          items: updatedItems.map((item) => {
            return {
              product: {
                id: item.product.id,
                name: item.product.name,
                attachments: item.product.attachments,
                same_day: item.product.same_day,
                future_day: item.product.future_day,
                min_order_amount: item.product.min_order_amount,
                price: item.product.price,
              },
              quantity: item.quantity,
              appliedModifiers: item.appliedModifiers,
            }
          }),
        }
        commit("SET_CART_LOADING", false)
        commit("SET_CART", newCart)
        newCart.date && commit("SET_DELIVERY_WINDOWS", newCart.date)
        state.deliveryAddress && dispatch("validateOrder")
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        this.$sentry.captureException(error)
      })
    state.isProduction &&
      this.$gtm.push({
        event: "add_to_cart",
        ...{
          currency: "GBP",
          value: state.cart.total,
          items: updatedItems.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: state.chosenMaker.name,
              item_category:
                state.productCategories &&
                state.productCategories.length &&
                state.productCategories.find(
                  (category) => category.id === item.categoryId
                )?.name,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      })
  }, 100),
  removeFromCart: debounce(async function (
    { commit, state, dispatch },
    payload
  ) {
    let updatedItems = state.cart.items
    const index = updatedItems.findIndex(
      (item) =>
        item.product.id === payload.product.id &&
        item.appliedModifiers.every(
          (modifier, index) =>
            modifier.id === payload.appliedModifiers[index].id
        )
    )
    updatedItems = [
      ...updatedItems.slice(0, index),
      {
        product: payload.product,
        quantity: payload.quantity,
        appliedModifiers: payload.appliedModifiers,
      },
      ...updatedItems.slice(index + 1),
    ].filter((item) => item.quantity > 0)

    if (updatedItems.length === 0) {
      dispatch("clearCart")
    } else {
      commit("SET_CART_LOADING", true)
      const body = {
        items: updatedItems.map((item) => {
          return {
            id: item.product.id,
            name: item.product.name,
            quantity: item.quantity,
            appliedModifiers: item.appliedModifiers,
          }
        }),
        maker_id: payload.maker.id,
      }

      await this.$axios
        .post(`${process.env.AND_DINE_API}/api/public/orders/cart`, body)
        .then((response) => {
          commit("SET_CART_LOADING", false)
          commit("SET_CART", {
            ...response.data,
            maker: {
              id: payload.maker.id,
              name: payload.maker.name,
              slug: payload.maker.slug,
              address: payload.maker.address,
              delivery_days: payload.maker.delivery_days,
              next_available: payload.maker.next_available,
              free_delivery_min_amount: payload.maker.free_delivery_min_amount,
              min_order_amount: state.isBiz
                ? payload.maker.min_order_amount || 1500
                : 1500,
            },
            date: payload?.date || false,
            items: updatedItems.map((item) => {
              return {
                product: {
                  id: item.product.id,
                  name: item.product.name,
                  attachments: item.product.attachments,
                  same_day: item.product.same_day,
                  future_day: item.product.future_day,
                  price: item.product.price,
                },
                quantity: item.quantity,
                appliedModifiers: item.appliedModifiers,
              }
            }),
          })
          state.deliveryAddress && dispatch("validateOrder")
        })
        .catch((error) => {
          commit("SET_CART_LOADING", false)
          this.$sentry.captureException(error)
        })
      state.isProduction &&
        this.$gtm.push({
          event: "remove_from_cart",
          ...{
            currency: "GBP",
            value: state.cart.total,
            items: updatedItems.map((item) => {
              return {
                item_id: item.id,
                item_name: item.name,
                item_brand: state.chosenMaker.name,
                item_category:
                  state.productCategories &&
                  state.productCategories.length &&
                  state.productCategories.find(
                    (category) => category.id === item.categoryId
                  )?.name,
                price: item.price,
                quantity: item.quantity,
              }
            }),
          },
        })
    }
  },
  100),
  createShareCart({ state }, id) {
    const cartString = JSON.stringify({
      ...state.cart,
      dealId: id || null,
    })
    return this.$axios.post(
      `${process.env.AND_DINE_API}/api/public/shareCart`,
      {
        cart: cartString,
        customer_id: null,
      },
      {}
    )
  },
  getShareCart({ commit }, id) {
    return this.$axios.get(
      `${process.env.AND_DINE_API}/api/public/shareCart/${id}`,
      {}
    )
  },

  validateOrder: debounce(async function ({ commit, state, dispatch }) {
    commit("SET_CART_LOADING", true)
    commit("SET_CHECKOUT_ERROR", false)
    const items = state.cart.items.map((item) => {
      return {
        id: item.product.id,
        name: item.product.name,
        quantity: item.quantity,
        appliedModifiers: item.appliedModifiers,
        itemPrice: item.product.price,
      }
    })

    const body = {
      items,
      same_day: state.cart?.date.sameDay,
      dealId: state.cart.dealId || null,
      biz: state.isBiz,
      customer_first_name: state.customer.first_name,
      customer_last_name: state.customer.last_name,
      delivery_date_time: `${state.cart.date.full}T${state.cart.date.selectedWindow}:00Z`,
      maker_id: state.cart.maker.id,
      discount_id: null,
      customer_phone: state.customer.phone,
      customer_email: state.customer.email,
      note: state.cart.orderNotes,
      delivery_note: state.cart.deliveryNotes,
      delivery_address: {
        zip: state.deliveryAddress.formatted?.zip,
        city: state.deliveryAddress.formatted?.city,
        line_1: state.deliveryAddress.formatted?.line_1,
        line_2: state.deliveryAddress.formatted?.line_2,
      },
      pick_up_address: {
        zip: state.cart.maker.address?.zip,
        city: state.cart.maker.address?.city,
        line_1: state.cart.maker.address?.line_1,
        line_2: state.cart.maker.address?.line_2,
      },
      billing_address: {
        zip: state.billingAddress?.formatted?.zip,
        city: state.billingAddress?.formatted?.city,
        line_1: state.billingAddress?.formatted?.line_1,
        line_2: state.billingAddress?.formatted?.line_2,
      },
      business_name: state.customer?.business_name,
      delivery_service: state.quiverRadius ? "Quiver" : "Onfleet",
      organisation_id:
        state.isBiz && state.organisation ? state.organisation.id : null,
    }

    const config = state.authenticated
      ? {
          headers: {
            accept: "application/json",
            "x-access-token-customer": state.authenticated.token,
            "content-type": "application/json",
          },
        }
      : {}

    await this.$axios
      .put(
        state.authenticated
          ? `${process.env.AND_DINE_API}/api/customers/orders/validate`
          : `${process.env.AND_DINE_API}/api/public/orders/validate`,
        body,
        config
      )
      .then((response) => {
        commit("SET_CART_LOADING", false)
        commit("SET_CHECKOUT_ERROR", false)
        commit("SET_ORDER", response.data)
        commit("SET_REWARDS", response.data.rewards)
        state.isProduction &&
          this.$gtm.push({
            event: "begin_checkout",
            ...{
              currency: "GBP",
              value: state.cart.subtotal,
              items: state.cart.items
                .filter((item) => item.quantity > 0)
                .map((item) => {
                  return {
                    item_id: item.product.sku,
                    item_name: item.product.name,
                    item_brand: state.cart.maker.name,
                    item_category:
                      state.productCategories &&
                      state.productCategories.length &&
                      state.productCategories.find(
                        (category) => category.id === item.product.category_id
                      )?.name,
                    price: item.product.price,
                    quantity: item.quantity,
                  }
                }),
            },
          })
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        commit(
          "SET_CHECKOUT_ERROR",
          error.response ? error.response.data : error.message
        )
        commit("SET_ORDER", false)
      })
  }, 400),

  async createOrder({ commit, state, dispatch }) {
    commit("SET_CART_LOADING", true)
    commit("SET_CHECKOUT_ERROR", false)
    const items = state.cart.items.map((item) => {
      return {
        id: item.product.id,
        name: item.product.name,
        quantity: item.quantity,
        appliedModifiers: item.appliedModifiers,
        itemPrice: item.product.price,
      }
    })

    const body = { items, ...state.order, dealId: state.cart.dealId || null }
    const config = state.authenticated
      ? {
          headers: {
            accept: "application/json",
            "x-access-token-customer": state.authenticated.token,
            "content-type": "application/json",
          },
        }
      : {}

    await this.$axios
      .post(
        state.authenticated
          ? `${process.env.AND_DINE_API}/api/customers/orders`
          : `${process.env.AND_DINE_API}/api/public/orders`,
        body,
        config
      )
      .then((response) => {
        commit("SET_CART_LOADING", false)
        commit("SET_CHECKOUT_ERROR", false)
        commit("SET_ORDER", response.data)
        commit("SET_REWARDS", response.data.rewards)
        state.isProduction &&
          this.$gtm.push({
            event: "begin_checkout",
            ...{
              currency: "GBP",
              value: state.cart.subtotal,
              items: state.cart.items
                .filter((item) => item.quantity > 0)
                .map((item) => {
                  return {
                    item_id: item.product.sku,
                    item_name: item.product.name,
                    item_brand: state.cart.maker.name,
                    item_category:
                      state.productCategories &&
                      state.productCategories.length &&
                      state.productCategories.find(
                        (category) => category.id === item.product.category_id
                      )?.name,
                    price: item.product.price,
                    quantity: item.quantity,
                  }
                }),
            },
          })
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        commit("SET_ORDER", false)
        commit(
          "SET_CHECKOUT_ERROR",
          error.response ? error.response.data : error.message
        )
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async updateOrderDelivery({ commit, dispatch }, payload) {
    const { id } = payload
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/public/orders/updateDelivery/${id}`,
        payload
      )
      .catch((error) => {
        commit("SET_CHECKOUT_ERROR", error.response.data)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async stripePayment({ commit, state, dispatch }, elements) {
    commit("SET_CART_LOADING", true)
    commit("SET_CHECKOUT_ERROR", false)
    await elements.submit()
    await this.$stripe
      .confirmPayment({
        elements,
        clientSecret: elements.clientSecret,
        handle_card_payment: false,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${state.customer.first_name} ${state.customer.last_name}`,
              email: state.customer.email,
            },
          },
        },
      })
      .then((invoice) => {
        if (invoice.error) {
          commit("SET_CHECKOUT_ERROR", invoice.error.message)
          commit("SET_CART_LOADING", false)
          return
        }
        if (state.isProduction) {
          state.order.delivery_service === "Onfleet" &&
            dispatch("onFleetZap", state.order)
          state.order.delivery_service === "Quiver" &&
            dispatch("quiverDelivery", state.order)
        }
      })
      .catch((error) => {
        commit("SET_CHECKOUT_ERROR", error)
        commit("SET_CART_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  async getOrders({ commit, state, dispatch }) {
    commit("SET_ACCOUNT_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }

    const filter =
      "filter=" + JSON.stringify([{ key: "paid", value: "Paid", exact: true }])

    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/customers/orders?${filter}`, config)
      .then((response) => {
        commit("SET_ORDERS", response.data.orders)
        commit("SET_ACCOUNT_LOADING", false)
      })
      .catch((error) => {
        commit("SET_ACCOUNT_LOADING", false)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async getOrderById({ commit, state, dispatch }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/customers/orders/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_ORDER", response.data)
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async getReviews({ commit }) {
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/reviews?limit=9999`)
      .then((response) => {
        commit("SET_REVIEWS", response.data.data)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_REVIEWS", false)
      })
  },
  async quiverQuotes({ state, commit, dispatch }) {
    const isCarla =
      state.cart?.maker.id === "5603a745-b178-4e02-bc91-2f8d645ba21e"
    commit("SET_CART_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-api-key": process.env.QUIVER_API_KEY,
        "content-type": "application/json",
      },
    }
    let body = {
      delivery: {
        address: {
          line1: `${state.deliveryAddress.formatted.line_1}`,
          line2: `${state.deliveryAddress.formatted.line_2}`,
          city: state.deliveryAddress.formatted.city,
          postcode: state.deliveryAddress.formatted.zip,
        },
      },
      collection: {
        merchantLocationId: "anddine-quiver-fulfilment",
      },
      customer: {
        first_name: state.customer?.first_name,
        last_name: state.customer?.last_name,
        phoneNumber: state.customer?.phone,
        emailAddress: state.customer?.email,
      },
    }
    if (!state.cart.date.sameDay) {
      body = JSON.stringify({
        ...body,
        collection: {
          ...body.collection,
          merchantLocationId: state.chosenMaker?.id,
        },
      })
    } else {
      body = JSON.stringify(body)
    }
    await this.$axios
      .post(process.env.QUIVER_QUOTES_URL, body, config)
      .then(() => {
        commit("SET_QUIVER_RADIUS", !isCarla)
        commit("SET_CART_LOADING", false)
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        commit("SET_QUIVER_RADIUS", false)
        if (
          error.response &&
          JSON.stringify(error.response).includes(
            "fulfilmentLocations: none found with merchantLocationId"
          )
        ) {
          dispatch("quiverLocation", state.cart)
        } else if (state.cart.date.sameDay) {
          commit(
            "SET_CHECKOUT_ERROR",
            "Same day delivery not available from your postcode. Please select another date."
          )
        }
        this.$sentry.captureException(error)
      })
  },
  async quiverLocation({ commit, state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-api-key": process.env.QUIVER_API_KEY,
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      address: {
        ...payload.maker.address,
        postcode: payload.maker.address.zip,
      },
      name: state.cart?.maker.name,
      merchantLocationId: state.cart?.maker.id,
      deliveryEcommerceEnabled: true,
      fulfilmentLocationType: "MERCHANT",
    })
    await this.$axios
      .post(process.env.QUIVER_LOCATION_URL, body, config)
      .then(() => {
        commit("SET_CART_LOADING", false)
        commit("SET_QUIVER_RADIUS", true)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        commit("SET_CART_LOADING", false)
        commit("SET_QUIVER_RADIUS", false)
      })
  },
  async quiverDelivery({ dispatch, state, commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-api-key": process.env.QUIVER_API_KEY,
        "content-type": "application/json",
      },
    }
    let body = {
      deliveryType: "WINDOW",
      delivery: {
        address: {
          line1: `${payload.delivery_address.line_1} ${payload.delivery_address.line_2}`,
          city: payload.delivery_address.city,
          postcode: payload.delivery_address.zip,
        },
        window: {
          from: this.$moment(payload.delivery_date_time)
            .utcOffset(0)
            .subtract(15, "minutes"),
          to: this.$moment(payload.delivery_date_time)
            .utcOffset(0)
            .add(15, "minutes"),
        },
        date: payload.delivery_date_time.slice(0, 10),
      },
      collection: {
        merchantLocationId: "anddine-quiver-fulfilment",
        window: {
          from: this.$moment(payload.delivery_date_time)
            .utcOffset(0)
            .subtract(1, "hours")
            .subtract(30, "minutes"),
          to: this.$moment(payload.delivery_date_time)
            .utcOffset(0)
            .subtract(1, "hours")
            .subtract(15, "minutes"),
        },
        date: payload.delivery_date_time.slice(0, 10),
      },
      notes: state.deliveryNotes,
      order: {
        merchantOrderId: payload.id.toString(),
        lineItems: payload.orderProducts.map((item) => {
          const modifiers = item.modifiers.map((mod) => mod.name).join(", ")
          const productName = modifiers
            ? `${item.product.name} (${modifiers})`
            : item.product.name

          return {
            product: {
              name: productName,
              merchantProductId: item.id.toString(),
              price: parseFloat(item.product.price / 100).toFixed(2),
              id: item.id,
              weight: 0,
              dimensions: {
                height: 0,
                width: 0,
                depth: 0,
              },
            },
            quantity: item.amount,
          }
        }),
        price: {
          subtotal: parseFloat(payload.subtotal / 100).toFixed(2),
          total: parseFloat(payload.total / 100).toFixed(2),
        },
      },
      customer: {
        first_name: payload.customer_first_name,
        last_name: payload.customer_last_name,
        phoneNumber: payload.customer_phone,
        emailAddress: payload.customer_email,
      },
    }
    if (!JSON.parse(payload.same_day)) {
      body = JSON.stringify({
        ...body,
        collection: {
          ...body.collection,
          merchantLocationId: payload.maker_id,
        },
      })
    } else {
      body = JSON.stringify(body)
    }

    await this.$axios
      .post(process.env.QUIVER_DELIVERY_URL, body, config)
      .then(() => {
        dispatch("quiverZap", payload)
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        if (payload.same_day === false) {
          dispatch("updateOrderDelivery", {
            id: payload.id,
            delivery_service: "Onfleet",
          })
          dispatch("onFleetZap", payload)
        } else {
          commit(
            "SET_CHECKOUT_ERROR",
            "There was an error booking your delivery, please try again"
          )
          throw error
        }
      })
  },
  async onFleetZap({ state, commit }, payload) {
    const body = {
      ...payload,
      notes: state.deliveryNotes,
      slug: payload.maker.slug,
      store_name: payload.maker.name,
      order_type: state.isBiz ? "Biz" : "Personal",
      pickup_after: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .subtract(1, "hours")
        .subtract(30, "minutes")
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      pickup_before: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .subtract(1, "hours")
        .subtract(15, "minutes")
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      dropoff_after: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .subtract(15, "minutes")
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      dropoff_before: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .add(15, "minutes")
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      delivery_date: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      day_before: `${this.$moment(payload.delivery_date_time)
        .utcOffset(-1)
        .subtract(1, "days")
        .hour(16)
        .minute(0)
        .second(0)
        .format("MM/DD/YYYY hh:mmA")} GMT`,
    }
    await this.$axios
      .post(process.env.ONFLEET_ZAP, JSON.stringify(body))
      .then(() => {
        commit("SET_CART_LOADING", false)
        window.location.href = `/success/?order_id=${payload.id}`
      })
      .catch((error) => this.$sentry.captureException(error))
  },
  async quiverZap({ commit }, payload) {
    const body = {
      ...payload,
      slug: payload.maker.slug,
      store_name: payload.maker.name,
      delivery_date: `${this.$moment(payload.delivery_date_time)
        .utcOffset(0)
        .hour(13)
        .minute(0)
        .second(0)
        .format("MM/DD/YYYY hh:mmA")} GMT`,
      day_before: `${this.$moment(payload.delivery_date_time)
        .utcOffset(0)
        .subtract("days", 1)
        .hour(16)
        .minute(0)
        .second(0)
        .format("MM/DD/YYYY hh:mmA")} GMT`,
    }
    await this.$axios
      .post(process.env.QUIVER_ZAP, JSON.stringify(body))
      .then(() => {
        commit("SET_CART_LOADING", false)
        window.location.href = `/success/?order_id=${payload.id}`
      })
      .catch((error) => {
        commit("SET_CART_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  async getOrganisation({ commit, state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    commit("SET_ORGANISATION_LOADING", true)
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/customers/organisations`, config)
      .then((response) => {
        commit("SET_ORGANISATION", response.data)
        commit("SET_ORGANISATION_LOADING", false)
      })
      .catch((error) => {
        commit("SET_ORGANISATION_LOADING", false)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  addOrgMember({ commit, state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/customers/organisations/addCustomer/${payload.organisationId}`,
        { email: payload.email },
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/customers/organisations`,
            config
          )
          .then((response) => {
            commit("SET_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  removeOrgMember({ commit, state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/customers/organisations/removeCustomer/${payload.organisationId}`,
        { email: payload.email },
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/customers/organisations`,
            config
          )
          .then((response) => {
            commit("SET_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  updateOrganisation({ commit, state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/customers/organisations/${payload.organisationId}`,
        payload,
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/customers/organisations`,
            config
          )
          .then((response) => {
            commit("SET_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  downloadInvoice({ state, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/pdf",
        "x-access-token-customer": state.authenticated.token,
        "content-type": "application/octet-stream; charset=utf-8",
      },
    }

    this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/customers/orders/downloadInvoice/${payload.orderId}`,
        config
      )
      .then((res) => {
        function base64ToArrayBuffer(data) {
          const binaryString = window.atob(data)
          const binaryLen = binaryString.length
          const bytes = new Uint8Array(binaryLen)
          for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
          }
          return bytes
        }
        const arrayBuffer = base64ToArrayBuffer(res.data)
        const url = window.URL.createObjectURL(
          new Blob([arrayBuffer], {
            type: "application/pdf",
          })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `&Dine Invoice - ${payload.orderRef}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
}

export const getters = {
  openMakers: (state) => {
    return (
      state.makers &&
      state.makers?.filter(
        (maker) =>
          maker.is_active &&
          maker.makersCategories.some(
            (category) => category.is_active && category.biz === state.isBiz
          )
      )
    )
  },
  closedMakers: (state) => {
    return (
      state.makers &&
      state.makers?.filter(
        (maker) =>
          !maker.is_active &&
          maker.attachments?.logo?.length > 0 &&
          maker.attachments?.profile?.length > 0 &&
          maker.makersCategories.some(
            (category) => category.is_active && category.biz === state.isBiz
          )
      )
    )
  },
  previousMakers: (state) => {
    return (
      state.orders &&
      state.makers &&
      state.makers?.filter(
        (maker) =>
          JSON.stringify(state.orders).includes(maker.id) &&
          maker.is_active &&
          maker.makersCategories.some(
            (category) => category.is_active && category.biz === state.isBiz
          )
      )
    )
  },
  cartItemCount: (state) => {
    let count = 0
    state.cart?.items?.length > 0
      ? state.cart.items.forEach((item) => {
          count += item.quantity
        })
      : (count = 0)
    return count
  },
  topReviews: (state) => {
    const top = state.reviews.filter(
      (review) => review.score >= 4 && review.customer
    )
    return Array.from(
      top
        .reduce((uniqueCustomers, review) => {
          const customer = review.customer
          if (!uniqueCustomers.has(customer)) {
            uniqueCustomers.set(customer, review)
          }
          return uniqueCustomers
        }, new Map())
        .values()
    )
  },
  navItems: (state) => {
    return [
      {
        id: 1,
        icon: "phone",
        name: "enquire",
        value: "Speak to our team",
        external: "https://landing.anddine.co.uk/enquire",
      },
      {
        id: 2,
        icon: "hand-wave",
        name: "about",
        value: "About us",
        link: "/about",
      },
      {
        id: 3,
        icon: "hat-chef",
        name: "join",
        value: "Sell with us",
        link: "/join",
      },
      {
        id: 4,
        icon: "user",
        name: state.authenticated ? state.user.first_name : "login",
        value: state.authenticated ? state.user.first_name : "Login",
        link: state.authenticated ? "/account" : "/login",
      },
    ]
  },
}
