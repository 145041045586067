
import { mapState, mapMutations, mapGetters } from "vuex"
export default {
  name: "NavBar",
  data: () => ({
    showMobileNav: false,
    isSearching: false,
    s3Image: process.env.S3_IMAGE,
  }),
  computed: {
    ...mapState([
      "deliveryFilter",
      "cart",
      "isBiz",
      "showSearch",
      "showCart",
      "showServices",
    ]),
    ...mapGetters(["navItems", "cartItemCount"]),
    correctRoute() {
      return this.currentPage !== "index"
    },
    currentPage() {
      return this.$route.name
    },
    showCount() {
      return this.cartItemCount > 0
    },
  },
  watch: {
    $route(to) {
      this.showMobileNav = false
      this.isSearching = false
      this.setShowServices(false)
    },
  },
  methods: {
    ...mapMutations({
      setCartStatus: "SET_CART_STATUS",
      setShowServices: "SET_SHOW_SERVICES",
    }),
    toggleSearch(val) {
      this.isSearching = val
      this.showMobileNav = false
      this.$emit("returnSearch", val)
    },
    toggleMobileNav(val) {
      this.showMobileNav = val
      this.isSearching = false
      this.returnCartClick(false)
    },
    returnCartClick(val) {
      this.$emit("returnCartClick", val)
    },
    updateShippingAddress(location) {
      this.setShippingAddress(location)
    },
    goToCheckout() {
      this.$router.push("/checkout")
      this.setCartStatus(false)
    },
  },
}
